



























































.item-addon--prices {
  .price-placeholder {
    font-size: 0.8rem;
    font-style: italic;
    line-height: 1.4em;
    color: darken(white, 25%);
  }
  .divider {
    flex: 0 1 auto;
    margin-right: 3px;
  }
}
.menu-layout .item-addon--prices.is-dirty {
  &::after {
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 3px;
  }
}
