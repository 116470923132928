









































































































@import '@design';

.item-addon {
  font-size: 0.75em;
  font-style: italic;
  font-weight: 500;
}
.item-addon--title,
.item-addon--prices {
  text-transform: uppercase;
  letter-spacing: 0.08em;
}

.item-addon--prices {
  margin-left: 0.5em;
}
.item-addon--separator {
  margin: 0 5px;
}
.menu-layout .menu-item--addons.is-dirty {
  &::after {
    display: none;
  }
}
.menu-layout .item-addon--title.is-dirty {
  &::after {
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 3px;
  }
}
